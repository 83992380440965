import React, {useEffect, useState} from 'react';
import {IFooter} from '@wearesection/bandai-react-components/organisms/Footer';
import Container from '@wearesection/bandai-react-components/atoms/Container';
import Box from '@mui/material/Box';
import {GetRouter} from '~/utils';
import dynamic from 'next/dynamic';
const Footer = dynamic(() => import('@wearesection/bandai-react-components/organisms/Footer'));

import {
  handleConvertLocaleToRegion,
  handleRedirectLanguageUtil,
  handleRedirectLocaleUtil,
} from '~/utils/locale-handler';
import {usePageSetting} from '~/providers/page-context.provider';
import {getCookie, setCookie} from 'cookies-next';

const adapter = (data: IFooter) => {
  return {
    ...data,
  };
};

const FooterOrganism: React.FC<IFooter> = props => {
  const investmentNeedsProps = adapter(props);
  const {dataSlug} = usePageSetting();

  const router = GetRouter();
  const {pathname, asPath, query, locale} = router;

  const [localeLocal, setLocaleLocal] = useState<any>(locale);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const newLocale = `${locale}`.toLowerCase();
      if (newLocale === 'tc') {
        const current = getCookie('currentToken') || 'HKD';
        if (current === 'TWD') {
          setLocaleLocal('tw-tc');
        }
        if (current === 'HKD') {
          setLocaleLocal('hk-tc');
        }
      } else {
        setLocaleLocal(locale);
      }
    }
  }, [locale]);

  const handleRedirectLanguage = (selectedLang: string) => {
    setCookie('currentToken', '');
    handleRedirectLanguageUtil(selectedLang, router, pathname, query, '/', dataSlug);
    localStorage.removeItem('navigation');
  };

  const handleRedirectLocale = (selectedLang: string) => {
    handleRedirectLocaleUtil(selectedLang, router, pathname, query, asPath, dataSlug);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#1E1E1E',
        marginTop: 'auto',
      }}
    >
      <Container>
        <Footer
          {...investmentNeedsProps}
          handleRedirectLanguage={handleRedirectLanguage}
          handleRedirectLocale={handleRedirectLocale}
          locale={localeLocal}
          dropdownProps={{
            ...investmentNeedsProps.dropdownProps,
            defaultValue: handleConvertLocaleToRegion(
              (localeLocal as string)?.toLocaleLowerCase() ?? 'sea'
            ),
          }}
        />
      </Container>
    </Box>
  );
};

export default React.memo(FooterOrganism);
